import { RED, GREEN, BLUE } from "shared";
import styled from "styled-components";
import { Link } from "gatsby";

const Base = styled(Link)`
  color: white;
  cursor: pointer;
  font-weight: 600;
  padding: ${({ theme: { rhythm } }) => rhythm(1 / 4)} 1.5rem;
  text-shadow: none;
  white-space: nowrap;
  border-radius: 3rem;
  box-sizing: border-box;
  background-image: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Red = styled(Base)`
  background-color: ${RED};
`;

const Green = styled(Base)`
  background-color: ${GREEN};
`;

const Blue = styled(Base)`
  background-color: ${BLUE};
`;

const Outline = styled(Base)`
  font-weight: 700;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  border: 3px solid white;
  background-color: transparent;
`;

const OutlineRed = styled(Outline)`
  color: ${RED};
  border-color: ${RED};
`;

const OutlineGreen = styled(Outline)`
  color: ${GREEN};
  border-color: ${GREEN};
`;

const OutlineBlue = styled(Outline)`
  color: ${BLUE};
  border-color: ${BLUE};
`;

export default {
  Red,
  Green,
  Blue,
  Outline,
  OutlineRed,
  OutlineGreen,
  OutlineBlue,
};
