import { RED } from "shared";
import Section, { BackgroundColor, TextAlign } from "components/section";
import CTA from "components/cta";
import ImageBlock from "components/imageBlock";
import image1 from "images/equipe-etcheverry-mindurry.jpg";
import image2 from "images/centre-de-tri-etcheverry-mindurry.jpg";
import * as React from 'react';
export default {
  RED,
  Section,
  BackgroundColor,
  TextAlign,
  CTA,
  ImageBlock,
  image1,
  image2,
  React
};