import React from "react";
import styled from "styled-components";

interface Props {
  content: string;
  width: number;
}

const ImageBlock = ({ content, width = 50 }: Props): JSX.Element => {
  return (
    <ImageContainer>
      <Image src={content} $width={width} />
    </ImageContainer>
  );
};

const ImageContainer = styled.div`
  text-align: center;
`;

const Image = styled.img<{ $width: number }>`
  width: ${({ $width }) => $width}%;
  margin: 0 auto;
  display: block;
`;

export default ImageBlock;
