import React, { FC, PropsWithChildren } from "react";
import Helmet from "react-helmet";
import styled, {
  createGlobalStyle,
  ThemeProvider,
  ThemeConsumer,
} from "styled-components";
import { Link } from "gatsby";
import Typography, { TypographyOptions } from "typography";
import typographyTheme from "typography-theme-ocean-beach";
import "@fontsource/inter/variable.css";

import logo from "images/logo.svg";

import { MEDIUM_WIDTH, BLUE, SITE_URL } from "shared";
import Menu from "components/menu";
import { BackgroundColor } from "./section";

const typography = new Typography({
  ...(typographyTheme as TypographyOptions),
  // TODO: use https://fonts.google.com/specimen/Inter
  baseFontSize: "18px",
  baseLineHeight: 1.9,
  scaleRatio: 2,
  bodyFontFamily: ["InterVariable", "Roboto", "sans-serif"],
  bodyWeight: 500,
  headerFontFamily: ["InterVariable", "Roboto", "sans-serif"],
  headerWeight: 800,
});

const GlobalStyle = createGlobalStyle`
  ${typography.toString()}
  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
  }
  :not(h1, h2, h3, h4, h5, h6) > strong { 
    font-weight: 800;
  }
  a { 
    color: ${BLUE}; 
  }
`;

interface Props {
  children: React.ReactNode;
  location: any;
  pageContext: any;
}

const LayoutComponent = ({ children, pageContext, location }: Props): JSX.Element => {
  const disableFooterLink = pageContext.frontmatter?.disableFooterLink ?? false
  const FooterTitleComponent: FC<PropsWithChildren<{ href?: string }>> = disableFooterLink ? FooterTitle : FooterLink
  const footerTitleComponentProps = disableFooterLink ? {} : { href: "/" }
  console.log(location)
  return (
    <ThemeProvider theme={typography}>
      <GlobalStyle />
      {pageContext.frontmatter && (
        <Helmet>
          <link rel="canonical" href={SITE_URL + location.pathname.replace(/\/$/, '')} />
          {pageContext.frontmatter.meta?.title && (
            <title>{pageContext.frontmatter.meta.title} </title>
          )}
          {pageContext.frontmatter.meta?.description && (
            <meta
              name="description"
              content={pageContext.frontmatter.meta.description}
            />
          )}
        </Helmet>
      )}
      <HeaderContainer>
        <ThemeConsumer>
          {({ rhythm }: Typography) => (
            <Header $rhythm={rhythm}>
              <TransparentLink to="/">
                <Image src={logo} $rhythm={rhythm} />
              </TransparentLink>
              <Menu />
            </Header>
          )}
        </ThemeConsumer>
      </HeaderContainer>
      <Main>{children}</Main>
      <Footer>
        <ThemeConsumer>
          {({ rhythm }: Typography) => (
            <>
              <FooterInner $rhythm={rhythm}>
                <FooterTitleComponent {...footerTitleComponentProps}>ETCHEVERRY MINDURRY</FooterTitleComponent><br />
                <strong>PAYS BASQUE - SUD LANDES</strong><br />
              </FooterInner>
              <FooterLink href="/">
                <Image src={logo} $rhythm={rhythm} />
              </FooterLink>
              <ColumnContainer>
                <Column $rhythm={rhythm}>
                  <strong>ADRESSE</strong><br />
                  Route départementale 254<br />64200 Bassussarry
                </Column>
                <Column $rhythm={rhythm}>
                  <strong>CONTACT</strong><br />
                  <a href="tel://0559412000">05 59 41 20 00</a><br />
                  <a href="mailto://etcheverry-mindurry@wanadoo.fr">contact@etcheverry-mindurry.fr</a><br />
                </Column>
                <Column $rhythm={rhythm}>
                  <strong>HORAIRES</strong><br />
                  Du lundi au vendredi<br />
                  7h30 - 12h et 13h30 - 17h
                </Column>
              </ColumnContainer>
            </>
          )}
        </ThemeConsumer>
      </Footer>
    </ThemeProvider>
  );
};

const HeaderContainer = styled.div`
  background-color: #fff;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.12);
  padding: 0 2rem;
`;

const Header = styled.section<Rhythmical>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 40em;
  margin: auto;
  padding: ${({ $rhythm }) => $rhythm(1)} 0;
  
  ${MEDIUM_WIDTH} {
    flex-direction: row;
  }
`;

const TransparentLink = styled(Link)`
  display: block;
  text-shadow: none;
  background-image: none;
  margin: 0;
`;

const Image = styled.img<Rhythmical>`
  display: block;
  height: ${({ $rhythm }) => $rhythm(3)};
  margin: 0 auto;
`;

const Main = styled.main``;

const Footer = styled.section``;

const FooterInner = styled.p<Rhythmical>`
  max-width: 40rem;
  margin: ${({ $rhythm }) => $rhythm(1 / 2)} auto;
  line-height: ${({ $rhythm }) => $rhythm(1)};
  font-size: .9rem;
  text-align: center;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-flow: column;
  padding: 2rem 0;
  margin-top: 2rem;
  background-color: ${BackgroundColor.DARK};

  ${MEDIUM_WIDTH} {
    flex-flow: row;
  }
`

const Column = styled.div<Rhythmical>`
  flex: 1;
  text-align: center;
  padding: 2rem 1rem;
  border-bottom:  1px solid #E0E0E0;
  line-height: ${({ $rhythm }) => $rhythm(1)};
  font-size: 0.75rem;

  ${MEDIUM_WIDTH} {
    padding: 0 1rem;
    border-bottom: none;
    border-right: 1px solid #CCC;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-right: none;
    border-bottom: none;
    padding-bottom: 0;
  }
`

const FooterTitle = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
  color: inherit;
`

const FooterLink = styled.a`
  font-size: 1.2rem;
  font-weight: bold;
  color: inherit;
`

export default LayoutComponent;
