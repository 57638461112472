import React from "react";
import styled from "styled-components";

import { MEDIUM_WIDTH } from "shared";
import Button from "components/button";

interface Props {
  children: string;
  label: string;
  to: string;
}

const CTA = ({ children, label, to }: Props): JSX.Element => {
  return (
    <Container>
      <Title>{children}</Title>
      <Button.Outline to={to}>{label}</Button.Outline>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: auto;
  padding-bottom: ${({ theme: { rhythm } }) => rhythm(1)};
  gap: 2rem;
  
  ${MEDIUM_WIDTH} {
    flex-direction: row;
    text-align: inherit;
  }
`;

const Title = styled.h2`
  color: white;
  margin: 0;
`;

export default CTA;
