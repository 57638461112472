import Section, { BackgroundColor, TextAlign } from "components/section";
import Map from "content/shared/map";
import image1 from "images/etcheverry-mindurry.jpg";
import * as React from 'react';
export default {
  Section,
  BackgroundColor,
  TextAlign,
  Map,
  image1,
  React
};