import React, { FC, PropsWithChildren } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { MEDIUM_WIDTH } from "shared";
import Button from "components/button";

const MenuItem: FC<PropsWithChildren<{ to: string }>> = (props) => {
  return typeof window !== "undefined" &&
    window.location.pathname === props.to ? (
    <ItemActive {...props} />
  ) : (
    <Item {...props} />
  );
};

const Menu = (): JSX.Element => {
  return (
    <Container>
      <MenuItem to="/">Accueil</MenuItem>
      <MenuItem to="/professionnels">Professionnels</MenuItem>
      <MenuItem to="/particuliers">Particuliers</MenuItem>
      <Button.Green to="/contact">Nous contacter</Button.Green>
    </Container>
  );
};

const Container = styled.menu`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  
  ${MEDIUM_WIDTH} {
    flex-direction: row;
  }
`;

const Item = styled(Link)`
  padding: ${({ theme: { rhythm } }) => rhythm(1 / 4)} 2rem;
  color: inherit;
  text-shadow: none;
  background-image: none;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }

  ${MEDIUM_WIDTH} {
    padding: ${({ theme: { rhythm } }) => rhythm(1 / 2)} 1rem;
  }
`;

const ItemActive = styled(Item)`
  text-shadow: none;
  white-space: nowrap;
  text-decoration: underline;
`;

export default Menu;
