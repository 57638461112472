import * as React from "react";
import styled, { useTheme } from "styled-components";
import Typography from "typography";

const Separator = () => {
  const { rhythm } = useTheme() as Typography;
  return <Line $rhythm={rhythm} />;
};

const Line = styled.hr<Rhythmical>`
  align-self: center;
  width: 50%;
  margin: ${({ theme: { rhythm } }) => `${rhythm(1)} 0 ${rhythm(2)}`};
  background-color: #ddd;
`;

export default Separator;
