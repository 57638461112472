import * as React from "react";
import styled, { useTheme } from "styled-components";
import Typography from "typography";
import { MEDIUM_WIDTH, SMALL_WIDTH } from "shared";

const MAX_WIDTH = 40; // in "rem"

export enum BackgroundColor {
  LIGHT = "#FFFFFF",
  DARK = "#F8F8F8",
  RED = "#c60636",
  GREEN = "#2c9c49",
  BLUE = "#1a74bd",
}

export enum TextAlign {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
  JUSTIFY = "justify",
}

interface Props {
  background?: BackgroundColor;
  children: React.ReactNode | React.ReactNode[];
  id?: string;
  textAlign?: TextAlign;
  width?: number;
}

const Section = ({
  background = BackgroundColor.LIGHT,
  children,
  id,
  textAlign = TextAlign.JUSTIFY,
  width = 100,
}: Props) => {
  const { rhythm } = useTheme() as Typography;
  return (
    <SectionContainer $background={background} $rhythm={rhythm} id={id}>
      <SectionInner $width={width} $textAlign={textAlign}>
        {children}
      </SectionInner>
    </SectionContainer>
  );
};

const SectionContainer = styled.section<
  {
    $background: BackgroundColor;
  } & Rhythmical
>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ $rhythm }) => $rhythm(2)} 2rem ${({ $rhythm }) => $rhythm(1)};
  background-color: ${({ $background }) => $background};
`;

const SectionInner = styled.div<{
  $textAlign: TextAlign;
  $width: number;
}>`
  ${({ $textAlign, $width }) => `
    display: flex;
    flex-direction: column;
    justify-content:  $textAlign};
    width: 100%;
    
    ${SMALL_WIDTH} {
      max-width: ${(MAX_WIDTH / 100) * $width}rem;
    }
    
    ${$textAlign !== TextAlign.JUSTIFY ? `
      text-align: ${$textAlign};
    ` : `
      text-align: ${TextAlign.LEFT};
      p {
        ${MEDIUM_WIDTH} { 
          text-align: ${TextAlign.JUSTIFY};
        }
      }
    `}
  `}
`;

export default Section;
