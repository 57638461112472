import * as React from "react";
import styled, { useTheme } from "styled-components";

import { RED, GREEN, BLUE } from "shared";
import Typography from "typography";

interface Props {
  children: number;
}

const StepFactory = (Container: React.FC<Rhythmical>): React.FC<Props> => {
  return ({ children }: Props) => {
    const { rhythm } = useTheme() as Typography;
    return (
      <Container $rhythm={rhythm}>
        <StepInner $rhythm={rhythm}>{children}</StepInner>
      </Container>
    );
  };
};

const StepContainer = styled.div<Rhythmical>`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 3rem;
  height: 3rem;
  margin-bottom: 1rem;
  border-radius: 1.5rem;
`;

const StepInner = styled.div<Rhythmical>`
  color: white;
  font-weight: 800;
  font-size: 1.5rem;
`;

const Red = styled(StepContainer)`
  background-color: ${RED};
`;

const Blue = styled(StepContainer)`
  background-color: ${BLUE};
`;

const Green = styled(StepContainer)`
  background-color: ${GREEN};
`;

export default {
  Red: StepFactory(Red),
  Blue: StepFactory(Blue),
  Green: StepFactory(Green),
};
