import React, { createRef, useEffect, useState } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import styled from "styled-components";

const GMap = ({
  center,
  zoom,
}: {
  center: google.maps.LatLngLiteral;
  zoom: number;
}) => {
  const ref = createRef<HTMLDivElement>();
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (ref.current && typeof map === "undefined") {
      setMap(new window.google.maps.Map(ref.current, { center, zoom }));
    }
  }, [ref]);

  useEffect(() => {
    const marker = new google.maps.Marker({
      map,
      position: center,
      title: 'Etcheverry Mindurry',
      clickable: true,
    });

    const info = new google.maps.InfoWindow({
      content: `
<div style="margin: .5rem">
  <div style="font-weight: bold; font-size: .8rem; padding-bottom: 8px;">Etcheverry Mindurry</div>
  <div style="padding-bottom: 8px;">
    Route départementale 254<br />
    64200 Bassussarry
  </div>
  <div>
    <a href="https://goo.gl/maps/mmeekvpzATper7jN7" target="_blank">
    Google Maps
    </a> | <a href="https://www.waze.com/en/live-map/directions/fr/nouvelle-aquitaine/bassussarry/entr-generale-etcheverry-et-mindurry?place=ChIJ85CDm-wTUQ0RxtpzIhaidAs">Waze</a>   
  </div>
<div>`,
      ariaLabel: "Uluru",
    });

    marker.addListener("click", () => {
      info.open({ anchor: marker, map });
    });
  }, [map]);

  return <Container ref={ref} id="map" />;
}

const MapCoverage = () => {
  return (
    <Wrapper apiKey="AIzaSyCelBstcNcXLeRDX-V1fFiTJ4j44biPp4M"    >
      <GMap center={{ lat: 43.450445, lng: -1.494535 }} zoom={17} />
    </Wrapper>
  );
};


export default MapCoverage;

const Container = styled.div`
  width: 100%;
  height: 70vh;
`
