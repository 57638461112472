import Section, { BackgroundColor, TextAlign } from "components/section";
import Step from "components/step";
import Separator from "components/separator";
import ImageBlock from "components/imageBlock";
import CTA from "components/cta";
import image1 from "images/centre-de-tri-2-etcheverry-mindurry.jpg";
import step1 from "images/assessment.png";
import step2 from "images/step2.png";
import step3 from "images/step21.png";
import step4 from "images/step22.png";
import step5 from "images/step3.png";
import * as React from 'react';
export default {
  Section,
  BackgroundColor,
  TextAlign,
  Step,
  Separator,
  ImageBlock,
  CTA,
  image1,
  step1,
  step2,
  step3,
  step4,
  step5,
  React
};